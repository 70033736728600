.card_A {
  border: 1px solid #ccc;
  border-left: 10px solid #2a76f4;
  border-right: 10px solid #2a76f4;
  border-radius: 8px;
  width: 300px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  cursor: pointer;
}

.card_B {
  border: 1px solid #ccc;
  border-left: 10px solid #f0d319;
  border-right: 10px solid #f0d319;
  border-radius: 8px;
  width: 300px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.card-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.1em;
}

.card-side {
  color: #2a76f4;
}

.card-details {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.card-details span {
  font-size: 0.9em;
  line-height: 1.5;
}

.card-capacity {
  margin-top: 16px;
  background-color: #e0e0e0;
  border-radius: 8px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.capacity-bar {
  background-color: #28a745;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.capacity-text {
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
}
