.title_modal_delete {
  text-align: center;
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  top: 25px;
  font-size: 16px !important;
}

.container_modal_delete {
  width: 360px;
  height: 252px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.container_modal_delete .swal2-actions {
  flex-direction: column-reverse !important;
}

.button_modal_delete {
  display: inline-block;
  width: 82px;
  height: 42px;
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-family: "Poppins" !important;
}

.button_modal_delete.yes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  margin-top: -20px !important;

}

.button_modal_delete.not {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}

.button_modal_delete:focus {
  box-shadow: none !important;
}

/*################################*/
.title_modal_cancel {
  text-align: center;
  font: normal normal normal 16px/22px Segoe UI;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  top: 25px;
  margin-bottom: 10px;
  font-size: 16px !important;
}

.container_modal_cancel {
  width: 360px;
  height: 252px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.container_modal_cancel .swal2-actions {
  flex-direction: column-reverse !important;
}

.button_modal_cancel {
  display: inline-block;
  width: 82px;
  height: 42px;
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-family: "Poppins" !important;
}

.button_modal_cancel.yes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  margin-top: -20px !important;

}

.button_modal_cancel.not {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}

.button_modal_cancel:focus {
  box-shadow: none !important;
}

.confirm_actions {
  display: flex;
  justify-content: right !important;
  width: 100%;
  padding-right: 18px;
}

.success_confirm_button {
  background-color: #0075E7;
}

.success_html {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  font-size: 16px !important;
}